import { atom, map } from "nanostores";

import API from "@/client/api";
import { loggerWithPrefix } from "@/lib/logger";
import { uiStore } from "@/stores/uiStore";
import { GraphScoreOutput, userMeta } from "@/types";
import { getUserLinkedinProfileURL } from "@/utils/entityUtils";
import _ from "lodash";

const logger = loggerWithPrefix("[graphStore]");

interface ConnectionScoreResponse {
  score: number;
}

class GraphStore {
  initialized = atom(false);
  enabled = atom(false);
  connectionScores = map<Record<string, GraphScoreOutput | null>>({});
  connectionScoresLoading = map<Record<string, boolean>>({});

  initialize = () => {
    if (this.initialized.get()) {
      return this.enabled.get();
    }
    this.initialized.set(true);
    if (!process.env.USE_MEMGRAPH) {
      this.enabled.set(false);
      return false;
    }
    const user = uiStore.user.get();
    const meta = userMeta(user);
    if (!meta.entity_id && !getUserLinkedinProfileURL(meta, false)) {
      logger.info("No entity is associated with this user");
      this.enabled.set(false);
      return false;
    }
    this.enabled.set(true);
    return true;
  };

  indexMe = async () => {
    if (!this.initialize()) {
      return;
    }
    try {
      await API.graphIndexMe({});
      logger.info("Successfully indexed myself");
    } catch (error) {
      logger.warn("Failed to index me", error);
    }
  };

  scoringCounter = 0;

  preloadGraphScores = async (entityIds: string[]) => {
    if (!this.initialize()) {
      return;
    }
    const chunks = _.chunk(entityIds, 5);
    for (const [index, chunk] of chunks.entries()) {
      await API.graphPreload({ entityIds: chunk });
    }
  };

  calculateConnectionScores = async (entityIds: string[]) => {
    if (!this.initialize()) {
      return;
    }

    const currentScores = this.connectionScores.get();
    const loadingMap = this.connectionScoresLoading.get();
    // Filter out entity IDs that we already have or are already loading
    const entitiesToProcess = entityIds.filter(
      (id) => currentScores[id] === undefined && !loadingMap[id],
    );

    if (entitiesToProcess.length === 0) {
      return;
    }

    logger.info(`Calculating connection scores for ${entitiesToProcess.length} entities`);

    // Mark entities as loading

    // Process each entity
    // Process entities in batches of 5
    const chunks = _.chunk(entitiesToProcess, 5);

    const thisScoringCounter = ++this.scoringCounter;
    for (const [index, chunk] of chunks.entries()) {
      logger.info(`Processing chunk`, index, chunk);
      const batchPromises = chunk.map(async (entityId) => {
        this.connectionScoresLoading.setKey(entityId, true);
        try {
          const response = await API.graphConnectionScore({
            targetEntityId: entityId,
          });
          if (!response.disabled) logger.info(`Connection score for ${entityId}:`, response);
          this.connectionScores.setKey(entityId, response as GraphScoreOutput);
          this.connectionScoresLoading.setKey(entityId, false);
        } catch (error) {
          logger.warn(`Failed to get connection score for ${entityId}`, error);
          return { entityId, score: 0 };
        }
      });

      // Wait for the current batch to complete before processing the next batch
      try {
        await Promise.all(batchPromises);
      } catch (error) {
        logger.warn(`Failed to get connection scores for chunk`, error);
      } finally {
        chunk.forEach((entityId) => {
          this.connectionScoresLoading.setKey(entityId, false);
        });
      }
      if (thisScoringCounter !== this.scoringCounter) {
        logger.info(
          `New graph requests came. Interrupting`,
          this.scoringCounter,
          thisScoringCounter,
        );
        break;
      }
    }

    // Clear loading state
    this.connectionScoresLoading.set({});
  };

  scoreEntity = async (entityId: string) => {
    if (!this.initialize()) {
      return;
    }
    logger.info(`Scoring entity ${entityId}`);
    const response = await API.graphConnectionScore({
      targetEntityId: entityId,
    });
    this.connectionScores.setKey(entityId, response as GraphScoreOutput);
  };
}

export const graphStore = new GraphStore();
